import * as React from "react"
import { graphql } from "gatsby"
import { useLocation } from "@reach/router"
import PortableText from "react-portable-text"

import { TypeInsightArticle } from "../app/createPages"
import Layout from "../components/layout/layout"
import Seo from "../components/seo/seo"
import Divider from "../components/divider/divider"
import ArticleTags from "../components/articles/articleTags"
import ArticleShareButtons from "../components/articles/articleShareButtons"
import serializers from "../components/serializers/serializers"
import ArticleImage from "../components/articles/articleImage"

type TypeData = {
  sanityInsight: TypeInsightArticle
}

type InsightArticleTemplateProps = {
  data: TypeData
}

const InsightsArticleTemplate = ({ data }: InsightArticleTemplateProps) => {
  const location = useLocation()

  const options: Intl.DateTimeFormatOptions = {
    dateStyle: "medium",
    timeStyle: "short",
  }

  return (
    <Layout>
      <Seo
        title={data?.sanityInsight.title}
        imageUrl={data?.sanityInsight.mainImage?.asset?.url}
        description={data?.sanityInsight.excerpt}
      />
      <article className="mt-5">
        <div className="container mw-800">
          <div className="row">
            <ArticleTags tags={data?.sanityInsight.tags} />
          </div>
          <h4>{data?.sanityInsight.title}</h4>
          <p>{`Written by ${data?.sanityInsight.author.name}`}</p>
          <p className="overline">
            {new Intl.DateTimeFormat(undefined, options).format(
              new Date(data?.sanityInsight.publishedAt)
            )}
          </p>
        </div>
        {data?.sanityInsight.mainImage && (
          <ArticleImage
            image={data?.sanityInsight.mainImage}
            alt={data?.sanityInsight.mainImageAltText}
          />
        )}
        <div className="container mw-800 mt-5">
          <Divider />
          <ArticleShareButtons
            url={location.href}
            article={data?.sanityInsight}
          />
          <Divider />
        </div>
        <div className="container mw-800 mt-5">
          <PortableText
            className=""
            content={data?.sanityInsight._rawBody}
            serializers={serializers}
          />
        </div>
      </article>
    </Layout>
  )
}

export const insightsArticleQuery = graphql`
  query InsightsArticleQuery($currentSlug: String) {
    sanityInsight(slug: { current: { eq: $currentSlug } }) {
      title
      excerpt
      mainImageAltText
      slug {
        current
      }
      mainImage {
        asset {
          gatsbyImageData(aspectRatio: 1.78)
          url
        }
      }
      id
      _rawBody(resolveReferences: { maxDepth: 10 })
      publishedAt
      author {
        name
      }
      category {
        title
        slug {
          current
        }
        description
      }
      tags {
        title
        slug {
          current
        }
        description
      }
    }
  }
`

export default InsightsArticleTemplate
